import React from 'react';
import {
  TimelineWithImages as IProps,
} from 'infrastructure/gatsby/types/timeline-with-images';
import Desktop from './desktop-timeline';
import MobileProcessAccordion from './mobile-accordion';

const TimelineWithImages: React.FC<IProps> = (content) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Desktop {...content} />
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <MobileProcessAccordion {...content} />
  </>
);

export default TimelineWithImages;
