import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import ProcessAccordion, {
  ProcessAccordionTheme,
} from '@shipae/components-sandbox/component/process-accordion';
import {
  AccordionTheme,
} from '@shipae/components-sandbox/component/accordion';
import {
  TimelineWithImages as IProps,
} from 'infrastructure/gatsby/types/timeline-with-images';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import styled from 'styled-components';
import { MobileSection } from '../../shared/mobile-section';
import { MobileTitle, SubTitle } from '../shared.styles';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type ThemeType = typeof ProcessAccordionTheme;

const ProcessAccordionThemeWithoutIcons: ThemeType = {
  ...ProcessAccordionTheme,
  Main: styled(ProcessAccordionTheme.Main)`
    &:before {
      left: 3px;
    }
  `,
  AccordionTheme: {
    ...AccordionTheme,
    Main: styled(AccordionTheme.Main)`
      padding: 0 0 0 2.5rem;
    `,
  },
};

const MobileProcessAccordion: React.FC<IProps> = ({
  sectionId,
  title,
  subTitle,
  timelineHexColor,
  milestone: milestones,
}) => (
  <MobileSection id={sectionId}>
    <MobileTitle>{title}</MobileTitle>
    <SubTitle>{subTitle}</SubTitle>
    <ProcessAccordion
      entries={milestones.map((milestone) => (
        {
          accentColor: timelineHexColor,
          title: milestone.title,
          content: (
            <RichText>
              <ReactMarkdown
                options={gloabalReactMdOptions}
              >
                {milestone.timelineDescription || ''}
              </ReactMarkdown>
            </RichText>
          ),
        })) || []}
      theme={ProcessAccordionThemeWithoutIcons}
    />
  </MobileSection>
);

export default MobileProcessAccordion;
