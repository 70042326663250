import { AppState } from '../../state';
import { initialState, TimelineWithImagesState } from './reducer';

export const getTimelineWithImages = (
  state: AppState,
): TimelineWithImagesState => (
  state.timelineWithImages
    ? state.timelineWithImages
    : initialState
);
