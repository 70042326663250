import React, { useEffect } from 'react';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';
import {
  TimelineWithImages as IProps,
} from 'infrastructure/gatsby/types/timeline-with-images';
import styled from 'styled-components';
import { fonts } from 'src/views/theme';
import ReactMarkdown from 'markdown-to-jsx';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTimelineWithImages,
} from 'src/application/lazy/timeline-with-images/selectors';
import {
  setTimelineIndex,
} from 'src/application/lazy/timeline-with-images/actions';
import { g200, white } from '@shipae/components-sandbox/component/colors';
import { loadComponent } from 'src/application/core/control/actions';
import { DesktopSection } from '../../shared/desktop-section';
import { Title, SubTitle } from '../shared.styles';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

const Flex = styled.div`
  display: flex;
  align-items: stretch;
`;

const ImageWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 56%;
`;

interface VisibilityProps {
  current: boolean;
}

type ImageProps = GatsbyImageProps & VisibilityProps;

const Image = styled(GatsbyImage)<ImageProps>`
  width: 100%;
  height: 100%;
  visibility: ${ (pr) => (pr.current ? 'visible' : 'hidden') };
  opacity: ${ (pr) => (pr.current ? 1 : 0) };
  transition: all 0.6s;

  position: absolute !important;
  top: 0;
  left: 0;
`;

interface ColorProps {
  color: string;
}

const ImageCaption = styled.p<ColorProps & VisibilityProps>`
  display: block;
  background-color: ${ (pr) => pr.color };

  visibility: ${ (pr) => (pr.current ? 'visible' : 'hidden') };
  opacity: ${ (pr) => (pr.current ? 1 : 0) };

  transition: all 0.6s;

  position: absolute;
  bottom: 5rem;
  left: 0;
  width: 39.5rem;
  max-width: 70%;

  ${ fonts.bodyl200 };
  color: ${ white() };
  padding: 3.5rem;
`;

const Timeline = styled.ul`
  list-style: none;

  position: relative;
  padding-left: 8rem;


  /* Vertical Line */
  &:before {
    display: block;
    background-color: ${ g200() };
    content: '';
    position: absolute;
    top: 1rem;
    bottom: 0;
    left: 4rem;
    width: 3px;
  }
`;

const Milestone = styled.li`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  position: relative;
  /* White mask hiding line for last item */
  /* Uncomment and check the line */
  &:last-child {
    &:before {
      display: block;
      background-color: ${ white() };
      content: '';
      position: absolute;
      top: 1.5rem;
      bottom: 0;
      left: -4rem;
      transform: translateX(-50%);
      width: 4rem;
    }
  }
`;

interface DotProps {
  isDotActive: boolean;
}

const MilestoneTitle = styled.h3<ColorProps & DotProps>`
  ${ fonts.displayxs300 }
  color: ${ (pr) => pr.color };
  cursor: pointer;

  position: relative;
  /* Colored Dots */
  &:before {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: calc(-4rem + 1px);
    display: block;
    content: '';
    // Px to avoid assymetric position on smaller vp
    width: 27px;
    height: 27px;
    background-color: ${ (pr) => (pr.isDotActive ? pr.color : g200()) };
    border-radius: 50%;
  }
`;

const TimelineWithImagesDesktop: React.FC<IProps> = ({
  sectionId,
  title,
  subTitle,
  timelineHexColor,
  milestone: milestones,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadComponent('timeline-with-images'));
  }, []);
  const {
    index: currentTimelineIndex,
  } = useSelector(getTimelineWithImages);
  return (
    <DesktopSection
      id={sectionId}
      data-test="timeline-with-images-desktop"
    >
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <Flex>
        <ImageWrapper>
          {milestones.map((milestone, index) => (
          milestone.image.localFile?.childImageSharp?.fluid
          && (
            <div data-test="timeline-with-images-desktop-image">
              <Image
                fluid={milestone.image.localFile?.childImageSharp?.fluid}
                alt={milestone.image.alternativeText || ''}
                objectFit="cover"
                objectPosition="center center"
                current={
                  currentTimelineIndex
                    ? index === currentTimelineIndex
                    : index === 0
                }
              />
              {milestone.imageCaption ? (
                <ImageCaption
                  color={timelineHexColor}
                  current={
                  currentTimelineIndex
                    ? index === currentTimelineIndex
                    : index === 0
                }
                >
                  {milestone.imageCaption}
                </ImageCaption>
              ) : null}
            </div>
          )
          ))}
        </ImageWrapper>
        <Timeline>
          {milestones.map((milestone, index) => (
            <Milestone>
              <MilestoneTitle
                color={timelineHexColor}
                isDotActive={
                currentTimelineIndex
                  ? currentTimelineIndex >= index
                  : index === 0
              }
                onClick={() => dispatch(setTimelineIndex(index))}
              >
                {milestone.title}
              </MilestoneTitle>
              <RichText>
                <ReactMarkdown
                  options={gloabalReactMdOptions}
                >
                  {milestone.timelineDescription || ''}
                </ReactMarkdown>
              </RichText>
            </Milestone>
          ))}
        </Timeline>
      </Flex>
    </DesktopSection>
  );
};

export default TimelineWithImagesDesktop;
