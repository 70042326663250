import { fonts } from 'src/views/theme';
import styled from 'styled-components';
import { Heading } from '../shared/heading';

export const Title = styled(Heading)`
  margin-bottom: 2rem;
  margin-top: 5rem;
`;

export const MobileTitle = styled.div`
  margin-bottom: 2rem;
  margin-top: 5rem;
  text-align: center;
  ${ fonts.displaym200 }
`;

export const SubTitle = styled.p`
  text-align: center;
  ${ fonts.bodyl200 }
  margin-bottom: 5rem;
`;
